import getAxiosInst from "../axios";
import URL from "../requestURL";

export async function getScheduleIndexes(params) {
  let res = await getAxiosInst().get(URL.GET_SCHEDULE_LIST, {
    params: {
      next: params?.next,
      previous: params?.prev,
      filter: params?.type,
      startDate: params?.startDate,
      endDate: params?.endDate,
    },
  });
  return res;
}
