import { StyledFooterWrapper } from "./style";
import logo from "../../assets/icons/logo.svg";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useAppDetails } from "../../hooks/useAppDetails";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes";
import { ContactUsRedirect } from "../../constants/externalLink";

export default function Footer() {
  const { loginDetail } = useAppDetails();
  const navigate = useNavigate();

  const handleQuestions = () => {
    if (loginDetail?.token) {
      navigate(routes.CONTACTUS);
    } else {
      window.open(ContactUsRedirect.CONTACT_US_FORM);
    }
  };
  return (
    <StyledFooterWrapper>
      <div className="left-section">Copyright © 2023, All rights reserved.</div>
      <div className="right-section">
        <div className="questions" onClick={handleQuestions}>
          <div className="head">
            <p>Questions?</p>
          </div>
          <div className="question-logo">
            <QuestionCircleFilled />
          </div>
        </div>
        <img src={logo} alt="" />
      </div>
    </StyledFooterWrapper>
  );
}
