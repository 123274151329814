const URL = {
  // User
  REGISTER: "/register/user",
  SEND_USER_QUERY: "/user/query",
  FORGET_USERNAME: "/register/forgot-username",
  LOGIN: "/user/login",
  LOGOUT: "/user/logout",
  NOTIFICATION: "/user/notification",

  // Invest
  ALL_INDEXES: "/index-template/template-for-user",
  USER_CONTRIBUTION: "/investment-request",
  GET_INDEX_TEMPLATE: "/index-instance/for-index",
  // GET_INDEX_TEMPLATE: "/index-template/for-user",

  GET_SINGLE_INDEX_TEMPLATE_: "/index-template",
  // INVESTMENT_REQUEST: "/investment-request/",
  INVESTMENT_REQUEST_PENDING: "/investment-request/pending",
  GET_PENDING_REQUEST: "/index-template/requests/pending",
  GET_LIST_DATA: "/index-template/for-user",

  //Instance
  GET_ALL_INSTANCE: "/index-instance/all-instances",
  GET_INSTANCE_DATA: "/index-instance",

  //Purchasing power
  GET_ALL_WITHDRAW_REQUESTS: "/withdraw-request/available-requests",
  WITHDRAW_AMOUNT: "/withdraw-request",

  // Kyc
  USER_KYC_SIGNIN: "/user/start-kyc",
  USER_KYC: "/user/kyc",

  //Schedule
  GET_SCHEDULE_LIST: "/index-instance/scheduled/events",

  //user
  USER_DATA: "/user",
  UPLOAD_PICTURE: "/user/profile-image",

  //others
  GENERATE_OTP: "/user/generate-otp",
  GET_WALLET_ADDRESS: "/user/generate-wallet",

  //assets
  GET_ASSETS_TEMPLATE: "/index-template/my-assets/all",

  //charts
  GET_INDEX_PERFOMANCE: "/charts/indexPerformance",
  GET_INVESTMENT_PERFOMANCE: "/charts/investmentPerformance",
  GET_TOTAL_PORTFOLIO_VALUE: "/charts/totalPortfolioValue/",
  GET_INSTANCE_AVERGAE: "/charts/average",
  GET_INDEX_CURRENT_AMOUNT: "/charts/iiValue",
  GET_TOKEN_PRICE: "/charts/index",
  GET_PRICES: "tokenPrices",
};

export default URL;
