const variables = {
  TOTAL_PORTFOLIO: "Total Portfolio Value",
  PURCHASING_POWER: "Purchasing Power",
  REQUESTS: "Requests",

  //Regex
  REGEX_FOR_PASSWORD:
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!#%*?&])[A-Za-z\\d@$!#%*?&]{8,}$",

  //Login page
  IMAGE_URL:
    "https://oci-site-assets.s3.us-west-2.amazonaws.com/blackMoneyPattern.png",
};

const cardTypes = {
  TOTAL_PORTFOLIO: "TOTAL_PORTFOLIO",
  PURCHASING_POWER: "PURCHASING_POWER",
  REQUESTS: "REQUESTS",
};

const passwordRequirements = [
  "At least 8 characters",
  "A mixture of both uppercase and lowercase letters",
  "A mixture of letters and numbers",
  "Inclusion of at least one special character, e.g., ! @ # ?",
];

const cardTitles = {
  TOTAL_PORTFOLIO: "Total Portfolio Value",
  PURCHASING_POWER: "Purchasing Power",
  REQUESTS: "Requests",
};

const cardComparisons = {
  callFromRequest: "Request",
};

const walletState = {
  UNINITIATED: "uninitiated",
};

const assetsStatus = {
  USDC: "USDC",
  USD: "USD",
};
export {
  variables,
  cardTitles,
  cardTypes,
  passwordRequirements,
  cardComparisons,
  walletState,
  assetsStatus,
};
