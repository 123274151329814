import styled from "styled-components";
import { Drawer } from "antd";

export const StyledDrawerContainer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: auto !important;
    height: 100vh;
    border: 1px solid ${(props) => props.theme.colors.inputBackgroundColor};
    left: 250px;
  }

  .ant-drawer,
  .ant-drawer-left,
  .ant-drawer-inline {
    position: absolute;
    height: 100vh;
    top: 0;
    display: ${(props) => (props.state ? "block" : "none")};
    overflow: scroll;
    padding: 2px 20px;
  }
  .ant-drawer-header-close-only {
    display: none;
  }

  .ant-drawer-body {
    padding: 5px 20px !important;
    background: ${(props) => props.theme.colors.bodyBackgroundColor};
    overflow-y: auto;

    overflow-x: hidden;
  }
  .ant-drawer-body::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  .ant-drawer-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .ant-drawer-body::-webkit-scrollbar-thumb {
    margin-top: 50px;
    background: grey;
    border-radius: 10px;
  }

  .ant-drawer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 500;
  }
`;
