import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

export const loginService = async (data) => {
  let res = await Auth.signIn(data.username, data.password);
  return res;
};

export const logoutService = async () => {
  return await Auth.signOut();
};

export const completeNewPassword = async (user, data) => {
  return await Auth.completeNewPassword(
    user, // the Cognito User Object
    data.password, // the new password
    {
      name: data.name,
      preferred_username: data.username,
      phone_number: `+1${data.phone}`,
      family_name: user.challengeParam.userAttributes.family_name,
    }
  );
};

export const signUpService = async (userData) => {
  const { username, password, email, phone_number, name } = userData;
  const { user } = await Auth.signUp({
    username,
    password,
    attributes: {
      email,
      phone_number,
      name,
    },
  });
  return user;
};

export const getMfaCode = async () => {
  const currentUser = await Auth.currentAuthenticatedUser().then(
    (user) => user
  );
  const code = await Auth.setupTOTP(currentUser);
  return code;
};

export const confirmSignIn = async (currentUser, challengeAnswer) => {
  try {
    let response = await Auth.confirmSignIn(
      currentUser, // Return object from Auth.signIn()
      challengeAnswer, // Confirmation code
      currentUser.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    );
    return response;
  } catch (error) {
    toast.error(error.message);
    return error;
  }
};
export const verifyMfaotpToken = async (challengeAnswer) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser().then(
      (user) => user
    );
    await Auth.verifyTotpToken(currentUser, challengeAnswer);
    let res = await Auth.setPreferredMFA(currentUser, "TOTP");
    toast.success("MFA enabled successfully");
    return res;
  } catch (error) {
    if (error?.message === "Code mismatch") {
      toast.error("Invalid MFA code !");
    }
    return error;
  }
};

export const disbaleMfa = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser().then(
      (user) => user
    );
    let res = await Auth.setPreferredMFA(currentUser, "NOMFA");
    toast.success("MFA disabled successfully");
    return res;
  } catch (error) {
    toast.error(error.message);
    return error;
  }
};

export const resendEmailVerificationOtp = async (username) => {
  try {
    await Auth.resendSignUp(username);
    toast.success("code resent successfully");
  } catch (err) {
    toast.error(err.message);
  }
};

export const forgotPasswordService = async (username) => {
  const currentUser = await Auth.forgotPassword(username);
  return currentUser;
};

export const forgotPasswordSubmitService = async (data) => {
  const result = await Auth.forgotPasswordSubmit(
    data.username,
    data.code,
    data.newPassword
  );
  return result;
};

export const resetPassword = async (data) => {
  let user = await Auth.currentAuthenticatedUser();
  let res = await Auth.changePassword(user, data.oldPassword, data.newPassword);
  return res;
};

/**
 * 
  @param {} callBacks contains success and error callbacks
  @param {} attribute  bank_info,last_login,last_logout,family_name, email, phone_number
  @param {} value value for the attribute
 */
export const updateUserAttributes = async (attribute, value) => {
  const currentUser = await Auth.currentAuthenticatedUser();
  const res = await Auth.updateUserAttributes(currentUser, {
    [attribute]: value,
  });
  return res;
};
