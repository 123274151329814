import ActionTypes from "../variables";
const initialState = [];
export const loginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_ACCESS_TOKEN:
    case ActionTypes.REMOVE_ACCESS_TOKEN:
      return {
        ...state,
        token: payload,
      };
    default:
      return state;
  }
};
