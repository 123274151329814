export const LightTheme = {
  type: "light",
  colors: {
    sidebarBackgroundColor: "#ffffff",
    white: "#ffffff",
    black: "#000",
    lightBlue: "#0EAECB",
    red: "#F15025",
    headerBg: "#000",
    headerText: "#fff",
    bodyBackgroundColor: "#e4e4e4",
    inputBackgroundColor: "#000",
    underline: "#C8C8C8",
    formTitle: "#24235c",
    formInputBackground: "#E5F5FB",
    formBodyBackground: "#333333",
    infoFontColor: "#FF714B",
    tableHeaderBg: "#E6E8E6",
    tableHeaderText: "#191919",
    pendingButtonBg: "#FFD153",
    tableBorderColor: "#767676",
    joinButtonBg: "#CE8B21",
    joinedButtonBg: "#01DDAE",
    label: "#808080",
    cardColor: "#fff",
    calendarCellBg: "#282525",
    themeGreen: "#01C39A",
    themeRed: "#FF714B",
    modalColor: "#fff",
    graphBorderColor: "#25D2F1",
    CalendarCellBg: "#D3D3D3",
    CalendarHeaderBg: "#B7B7B7",
    CalendarInCellBg: "#ABABAB",
  },
  fontSize: [8, 12, 14, 16, 18, 24, 28, 32, 64, 10],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 320, 512],
  borderRadius: [2, 4, 8, 12, 16, 20],
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  breakPoints: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "536px",
  },
};

export const DarkTheme = {
  type: "dark",
  colors: {
    sidebarBackgroundColor: "#164D91",
    white: "#ffffff",
    black: "#000",
    lightBlue: "#0EAECB",
    red: "red",
    headerBg: "grey",
    headerText: "#fff",
    bodyBackgroundColor: "#000000",
    inputBackgroundColor: "#ffffff",
    underline: "#C8C8C8",
    formTitle: "#24235c",
    formInputBackground: "#E5F5FB",
    formInputSignupBg: "#CBCBCB",
    placeHolderFont: "#191919",
    infoFontColor: "#FF714B",
    tableHeaderBg: "#E6E8E6",
    tableHeaderText: "#191919",
    pendingButtonBg: "#FFD153",
    tableBorderColor: "#767676",
    joinButtonBg: "#CE8B21",
    joinedButtonBg: "#01DDAE",
    formBodyBackground: "#333333",
    scheduleHeaderBg: "#4F4F4F",
    label: "#808080",
    cardColor: "#282525",
    calendarCellBg: "#282525",
    themeGreen: "#01C39A",
    themeRed: "#FF714B",
    modalColor: "#6e6a6a",
    graphBorderColor: "#25D2F1",
    CalendarCellBg: "#252525",
    CalendarHeaderBg: "#B7B7B7",
    CalendarInCellBg: "#505050",
  },
  fontSize: [8, 12, 14, 16, 18, 24, 28, 32, 64, 10, 52],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 320, 512],
  borderRadius: [2, 4, 8, 12, 16, 20],
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  breakPoints: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "536px",
  },
  fontFamily: {
    primary: "Open",
  },
};
