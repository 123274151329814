export const getFirstLetter = (x) => {
  return x
    ?.split(" ")
    ?.map((word, i) => (i < 2 ? word[0] : ""))
    ?.join("");
};

export const getQueryString = (params) => {
  let query = Object.keys(params)?.filter(
    (key) =>
      params[key] !== undefined && params[key] !== null && params[key] !== ""
  );
  return query.map((key) => key + "=" + params[key]).join("&");
};

export const getPercentageOfMinimumFunding = (
  invested_amount,
  minimum_capital_invest
) => {
  if (invested_amount && minimum_capital_invest) {
    if (
      Math.round(+invested_amount * 100) / parseInt(minimum_capital_invest) >
      100
    ) {
      return 100;
    } else {
      let x =
        Math.round(+invested_amount * 100) / parseInt(minimum_capital_invest);
      return x.toFixed(2);
    }
  } else {
    return 0;
  }
};
