import styled from "styled-components";
import { variables } from "../../constants/variables";

export const StyledWelcomeWrapper = styled.div`
  height: 100vh;
  background: transparent url(${variables.IMAGE_URL});
  color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  .mainBlockWelcome {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-top: -100px;
    align-items: center;
    justify-content: space-evenly;
    img {
      width: 215px;
      height: 205px;
    }
    .innerBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      height: 190px;
      .welcomeTitle {
        font-size: 50px;
        font-weight: bold;
      }
      .primary-button {
        background: ${(props) => props.theme.colors.red};
        border-radius: 60px;
        border: none;
        display: flex;
        font-size: ${(props) => props.theme.fontSize[1]}px;
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.colors.white};
        font-weight: 600;
        width: 166px;
        height: 35px;
      }
      .ant-form-item-control-input-content {
        justify-content: center;
        display: flex;
        align-items: center;
      }
      .login-btn {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .requestAccess {
        cursor: pointer;
        margin-top: 10px;
        font-size: ${(props) => props.theme.fontSize[3]}px;
        color: ${(props) => props.theme.colors.themeGreen};
      }

      .loginTitle {
        padding-top: 13px;
        font-size: ${(props) => props.theme.fontSize[3]}px;
        font-weight: bold;
      }
      .ant-form-item-label > label {
        color: ${(props) => props.theme.colors.inputBackgroundColor};
        font-size: ${(props) => props.theme.fontSize[1]}px;
      }
      .ant-form-item {
        margin-top: 7px;
        margin-bottom: 0px;
      }
      .ant-form-item-label > label.ant-form-item-required::before {
        content: none;
      }
      .ant-form {
        line-height: 0.5715;
      }
      .forget {
        cursor: pointer;
        width: 65%;
        padding-top: 10px;
        font-size: ${(props) => props.theme.fontSize[1]}px;
        color: ${(props) => props.theme.colors.themeGreen};
      }
    }
  }
`;
