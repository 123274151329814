import { Button, Form, Input } from "antd";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../components/header";
import Spinner from "../../../components/spinner/Spinner";
import errors from "../../../constants/error";
import routes from "../../../constants/routes";
import loginImage from "../../../assets/icons/loginImage.svg";
import Footer from "../../../components/footer/Footer";

import { StyledWelcomeWrapper } from "../../loginPage/style";
import { forgetUsername } from "../../../services/forget-username/forgetUsername";

export default function ForgetUsername() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const forgetUsernameCall = useCallback(
    async (params) => {
      try {
        setLoading(true);
        let res = await forgetUsername(params);
        if (res) {
          toast.success(res?.data?.message);
          navigate(routes.LOGIN);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [navigate]
  );

  const submitHandler = async (value, type) => {
    forgetUsernameCall({ email: value.email });
  };

  return (
    <>
      <Header />
      <StyledWelcomeWrapper>
        <div className="mainBlockWelcome">
          <img src={loginImage} alt="" />
          <div className="innerBlock">
            <span className="loginTitle">Forgot Username</span>
            {loading && <Spinner />}
            <Form
              layout="vertical"
              form={form}
              name="OtpConfirmForm"
              autoComplete="off"
              onFinish={submitHandler}
              initialValues={{
                remember: true,
              }}
            >
              (
              <Form.Item
                name="email"
                label="Email*"
                rules={[
                  { required: true, message: errors.ENTER_EMAIL },
                  { type: "email", message: errors.VALID_EMAIL },
                ]}
                normalize={(value) => value.trim()}
              >
                <Input
                  placeholder="Enter Email"
                  onKeyDown={(event) => {
                    if (event.which === 32) {
                      event.target.value = event.target.value.replace(
                        /^\s+/,
                        ""
                      );
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              )
              <Form.Item>
                <Button className="primary-button login-btn" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </StyledWelcomeWrapper>
      <Footer />
    </>
  );
}
