import ActionTypes from "../variables";
const initialState = false;
export const drawerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_DRAWER_STATUS:
      return {
        ...state,
        data: payload,
      };

    default:
      return state;
  }
};
