import ActionTypes from "../variables";

const setUserData = (data) => {
  return {
    type: ActionTypes.SET_USER_DATA,
    payload: data,
  };
};

const removeUserData = () => {
  return {
    type: ActionTypes.REMOVE_USER_DATA,
    payload: null,
  };
};

const removeChallangeName = () => {
  return { type: ActionTypes.REMOVE_CHALLANGE_NAME, payload: null };
};

const addChallangeName = (data) => {
  return { type: ActionTypes.ADD_CHALLANGE_NAME, payload: data };
};

const addProfilePicture = (data) => {
  return { type: ActionTypes.ADD_PROFILE_PIC, payload: data };
};

const deductWalletAmount = (data) => {
  return { type: ActionTypes.DEDUCT_WALLET_AMOUNT, payload: data };
};

const updatePendingAmount = (data) => {
  return { type: ActionTypes.UPDATE_PENDING_AMOUNT, payload: data };
};

const updateWalletAddress = (data) => {
  return { type: ActionTypes.UPDATE_WALLET_ADDRESS, payload: data };
};

const updatePortfolio = (data) => {
  return { type: ActionTypes.UPDATE_PORTFOLIO, payload: data };
};

export {
  setUserData,
  removeUserData,
  removeChallangeName,
  addChallangeName,
  addProfilePicture,
  deductWalletAmount,
  updatePendingAmount,
  updateWalletAddress,
  updatePortfolio,
};
