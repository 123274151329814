import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { signupReducer } from "./signupReducer";
import { themeReducer } from "./themeReducer";
import { investReducer } from "./investReducer";
import { userReducer } from "./userReducer";
import { assetReducer } from "./assetReducer";
import { drawerReducer } from "./draweReducer";

const persistConfig = {
  key: "persist-key",
  storage,
};

const appReducers = combineReducers({
  signupDetails: signupReducer,
  investDetail: investReducer,
  assetDetails: assetReducer,
  drawerDetails: drawerReducer,
});

const appPersistReducer = combineReducers({
  loginDetail: loginReducer,
  themeDetail: themeReducer,
  userData: userReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appPersistReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, appPersistReducer);
const basicReducers = combineReducers({
  appDetails: appReducers,
  perssisted: persistedReducer,
});

export default rootReducer;
export { basicReducers };
