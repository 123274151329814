import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import { basicReducers } from "./reducer/index";
import persistStore from "redux-persist/es/persistStore";

const store = configureStore({
  reducer: basicReducers,

  devTools: process.env.CLIENT_APP_ENV !== "production",
  middleware: () =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(thunk),
});

const persistor = persistStore(store);
export default store;
export { persistor };
