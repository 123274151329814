import styled from "styled-components";

export const StyledPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .next-arrow {
    .anticon svg {
      display: inline-block;
      height: 50px;
      width: 76px;
      color: #fd9379;
      padding-bottom: 18px;
    }
  }
`;
