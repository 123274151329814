import URL from "../requestURL";
import axios from "axios";
import jwtDecode from "jwt-decode";
import localStorageHelper from "../../helper/localStorageHelper";

function getAxiosChartInst() {
  let instance = axios.create({
    baseURL: process.env.REACT_APP_CHART_API_BASE_URL,
  });
  const removeUserData = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  if (localStorageHelper.getLocalStorageItem("token")) {
    let data = jwtDecode(localStorageHelper.getLocalStorageItem("token"));
    let currentTime = new Date().getTime();
    if (new Date(data.exp * 1000) < currentTime) {
      removeUserData();
    } else {
      instance = axios.create({
        baseURL: process.env.REACT_APP_CHART_API_BASE_URL,
        headers: {
          Authorization: `Bearer ${localStorageHelper.getLocalStorageItem(
            "token"
          )}`,
        },
      });
    }
  }
  return instance;
}

export async function getIndexPerfomance(params) {
  let res = await getAxiosChartInst().get(
    `${URL.GET_INDEX_PERFOMANCE}/${params?.id}`
  );
  return res;
}

export async function getInvestmentPerfomanceGraph(params) {
  let res = await getAxiosChartInst().get(`${URL.GET_INVESTMENT_PERFOMANCE}`, {
    params: {
      instance_id: params?.instance_id,
      wallet_address: params?.wallet_address,
      forTemplate: params?.type && true,
    },
  });
  return res;
}

export async function getInvestmentAverage(params) {
  let res = await getAxiosChartInst().get(
    `${URL.GET_INSTANCE_AVERGAE}/${params?.walletAddress}`,
    {}
  );
  return res;
}

export async function getTotalPortfolioValue(params) {
  let res = await getAxiosChartInst().get(
    `${URL.GET_TOTAL_PORTFOLIO_VALUE}${params?.wallet_address}`
  );
  return res;
}

export async function getIndexCurrentAmount(data, callFrom) {
  const obj = {};

  for (let key in data) {
    if (Array.isArray(data[key])) {
      for (let i = 0; i < data[key]?.length; i++) {
        obj[`instance_ids_array[${i}]`] = data[key][i];
      }
    } else {
      obj["wallet_address"] = data[key];
    }
  }

  let res = await getAxiosChartInst().get(URL.GET_INDEX_CURRENT_AMOUNT, {
    params: callFrom === "template-detail" ? data : obj,
  });
  return res;
}

export async function getTokenPrice(params) {
  let res = await getAxiosChartInst().get(
    `${URL.GET_TOKEN_PRICE}/${params?.id}/${URL.GET_PRICES}`
  );
  return res;
}
