const Action = {
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_ID_TOKEN: "SET_ID_TOKEN",
  REMOVE_ACCESS_TOKEN: "REMOVE_ACCESS_TOKEN",
  //userAction
  SET_USER_DATA: "SET_USER_DATA",
  REMOVE_USER_DATA: "REMOVE_USER_DATA",
  REMOVE_CHALLANGE_NAME: "REMOVE_CHALLANGE_NAME",
  ADD_CHALLANGE_NAME: "ADD_CHALLANGE_NAME",
  ADD_PROFILE_PIC: "ADD_PROFILE_PIC",
  DEDUCT_WALLET_AMOUNT: "DEDUCT_WALLET_AMOUNT",
  UPDATE_PENDING_AMOUNT: "UPDATE_PENDING_AMOUNT",
  UPDATE_WALLET_ADDRESS: "UPDATE_WALLET_ADDRESS",
  UPDATE_PORTFOLIO: "UPDATE_PORTFOLIO",

  //Assets Action
  ADD_ASSET_DETAILS: "ADD_ASSET_DETAILS",

  //signupAction
  SET_USER_DETAILS: "SET_USER_DETAILS",
  UPDATE_SIGNUP_USER_DETAILS: "UPDATE_SIGNUP_USER_DETAILS",

  THEME_TYPE: "THEME_TYPE",
  CHANGE_THEME: "CHANGE_THEME",
  SET_INVEST_DETAILS: "SET_INVEST_DETAILS",

  //drawerAction
  SET_DRAWER_STATUS: "SET_DRAWER_STATUS",
};

export default Action;
