import getAxiosInst from "../axios";
import URL from "../requestURL";

export async function getListViewData(params) {
  let res = await getAxiosInst().get(URL.GET_LIST_DATA, {
    params: { next: params?.next, previous: params?.prev },
  });
  return res;
}

export async function userNotification(params) {
  let res = await getAxiosInst().get(URL.NOTIFICATION, {
    params: { next: params?.next, previous: params?.prev },
  });
  return res;
}
