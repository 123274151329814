import styled from "styled-components";

export const StyledFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.formBodyBackground};
  color: ${(props) => props.theme.colors.headerText};
  height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  p {
    margin-top: revert;
  }
  .left-section {
    flex: 1;
    padding: 5px 15px;
    font-size: 12px;
  }
  .right-section {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;

    justify-content: right;
    .questions {
      display: flex;
      padding: 0px 15px !important;
      gap: 10px;
      align-items: center;
      padding: 0px 0px;
      height: 40px;
      cursor: pointer;
      background: red;
      p {
        font-size: 16px;
      }
    }
    img {
      width: 25px;
      height: 25px;
    }
  }
`;
