import ActionTypes from "../variables";
const initialState = [];
export const signupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.UPDATE_SIGNUP_USER_DETAILS:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.SET_USER_DETAILS:
      return {
        ...state,
        currentUserDetails: payload,
      };
    default:
      return state;
  }
};
