import axios from "axios";
import jwtDecode from "jwt-decode";
import localStorageHelper from "../helper/localStorageHelper";
import { persistor } from "../store/store";
export default function getAxiosInst() {
  let instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  instance.interceptors.response.use(
    function (response) {
      // Do something with the response data
      return response;
    },
    function (error) {
      // Do something with response error
      if (error.response.data.message === "No auth token provided") {
        removeUserData();
        return Promise.reject(error);
      }
    }
  );
  const removeUserData = () => {
    window.localStorage.clear();
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    localStorage.clear();
    window.location.reload();
  };

  if (localStorageHelper.getLocalStorageItem("token")) {
    let data = jwtDecode(localStorageHelper.getLocalStorageItem("token"));
    let currentTime = new Date().getTime();
    if (new Date(data.exp * 1000) < currentTime) {
      removeUserData();
    } else {
      instance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
          Authorization: `Bearer ${localStorageHelper.getLocalStorageItem(
            "token"
          )}`,
        },
      });
    }
  }
  return instance;
}
