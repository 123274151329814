import { BarsOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { StyledPaginationWrapper } from "../../../pagination/style";
import Spinner from "../../../spinner/Spinner";
import { StyledDrawerContentWrapper } from "../../style";
import { getScheduleIndexes } from "../../../../services/schedule/scheduleServices";
import Drawer from "../../../drawer/Drawer";
import { useAppDetails } from "../../../../hooks/useAppDetails";

export default function DrawerContent({ open, onclose, setOpen, placement }) {
  const [indexData, setIndexData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { loginDetail } = useAppDetails();

  const [prevLimit, setPrevLimit] = useState();
  const [pagination, setPagination] = useState({
    hasMore: "",
    next: "",
    limit: "",
    hasPrev: "",
    prev: "",
  });

  const fetchAllIndexes = useCallback(async (params) => {
    try {
      setLoading(true);
      let res = await getScheduleIndexes(params);
      if (res) {
        setIndexData((prev) => [...prev, ...res.data.data]);
        if (params?.next) {
          setPagination((prevState) => ({ ...prevState, hasPrev: true }));
        }

        setPagination((prevState) => ({
          ...prevState,
          hasMore: res?.data?.hasMore,
          next: res?.data?.next,
          prev: res?.data?.previous,
        }));

        //check if next on first call and set has prev true
        //if prev and first prev same
        if (prevLimit === res?.data?.previous) {
          setPagination((prevState) => ({ ...prevState, hasPrev: false }));
        }
        //setting previous on first api call
        if (!prevLimit) {
          setPrevLimit(res?.data?.previous);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loginDetail.token) {
      fetchAllIndexes({ type: "matured" });
    }
  }, [fetchAllIndexes, loginDetail]);

  const onNext = () => {
    fetchAllIndexes({ next: pagination?.next });
  };

  return (
    <Drawer open={open} onclose={onclose} setOpen={setOpen} placement="left">
      <StyledDrawerContentWrapper>
        {loading && <Spinner />}
        <div className="drawer-header">
          <div className="bars">
            <BarsOutlined />
            <p>Quick List - My Indexes</p>
          </div>
          <div className="onclose">
            <CloseOutlined onClick={onclose} />
          </div>
        </div>

        <div className="drawer-table">
          <table>
            <thead>
              <tr>
                <th>ENTITY / INSTANCE</th>
                <th className="addMergin">MATURITY DATE</th>
                <th className="addMergin">VALUE</th>
              </tr>
            </thead>

            {indexData?.map((data, index) => {
              return (
                <tbody key={index}>
                  <td className="entity-font">{data?.instance_name}</td>
                  <td className="addLeftMergin">
                    {moment(data?.maturity_date).format("MM/DD/YYYY")}
                  </td>
                  <td className="addLeftMergin">
                    {Number.isInteger(data?.invested_amount)
                      ? data?.invested_amount
                      : 0}
                  </td>
                </tbody>
              );
            })}
          </table>
          {pagination?.hasMore && (
            <StyledPaginationWrapper>
              <div className="next-arrow">
                <DownOutlined onClick={onNext} />
              </div>
            </StyledPaginationWrapper>
          )}
        </div>
      </StyledDrawerContentWrapper>
    </Drawer>
  );
}
