import ActionTypes from "../variables";
const initialState = {};
export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_USER_DATA:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.REMOVE_USER_DATA:
      return null;
    case ActionTypes.REMOVE_CHALLANGE_NAME:
      delete state.challengeName;
      return {
        ...state,
      };
    case ActionTypes.ADD_CHALLANGE_NAME:
      return {
        ...state,
        challengeName: payload,
      };
    case ActionTypes.ADD_PROFILE_PIC:
      return {
        ...state,
        ...(state.attributes.profile = payload),
      };
    case ActionTypes.DEDUCT_WALLET_AMOUNT:
      return {
        ...state,
        ...(state.attributes.balances.usdcBalance =
          state.attributes?.balances.usdcBalance - payload),
      };
    case ActionTypes.UPDATE_PENDING_AMOUNT:
      return {
        ...state,
        ...((state.attributes.pending_data.pending_amount =
          state.attributes?.pending_data.pending_amount + payload),
        (state.attributes.pending_data.pending_requests =
          state.attributes?.pending_data.pending_requests + 1)),
      };
    case ActionTypes.UPDATE_WALLET_ADDRESS:
      return {
        ...state,
        ...((state.attributes.wallet_address = payload.wallet_address),
        (state.attributes.balances.usdcBalance = payload.balances.usdcBalance),
        (state.attributes.pending_data.pending_amount =
          payload.pending_data.pending_amount)),
      };
    case ActionTypes.UPDATE_PORTFOLIO:
      return {
        ...state,
        updatePortfolio: payload,
      };
    default:
      return state;
  }
};
