import { StyledDrawerContainer } from "./style";
const Drawer = ({ open, onclose, setOpen, placement, children }) => {
  return (
    <>
      <StyledDrawerContainer
        state={open}
        placement={placement}
        closable={false}
        onClose={onclose}
        getPopupContainer={(trigger) => trigger.parentNode}
        zIndex={500}
        open={open}
      >
        {children}
      </StyledDrawerContainer>
    </>
  );
};
export default Drawer;
