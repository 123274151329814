const errors = {
  ENTER_NAME: "Please enter name",
  ENTER_USERNAME: "Please enter your username",
  ENTER_PASSWORD: "Please enter your password",
  ENTER_OLD_PASSWORD: "Please enter your old password",
  ENTER_CONFIRM_PASSWORD: "Please confirm your password",
  FIRST_NAME: "Please enter your name",
  LAST_NAME: "Please enter your last name",
  VALID_EMAIL: "Please enter valid E-mail",
  ENTER_EMAIL: "Please enter your E-mail",
  PHONE_NUMBER: "Please enter your phone number",
  INVALID_PHONE_NUMBER: "Phone number should consist of at least 10 digits.",
  VALID_PHONE_NUMBER: "Phone no. should be less than 10 digits",
  ENTER_OTP: "Please enter your OTP",
  PASSWORD_CANNOT_SAME: "Old password and new password can't be same",
  PASSWORD_DID_NOT_MATCH: "Password Mismatch",
  ACCEPT_ONLY_LETTERS: "Symbol must be a string",
  MIN_PASSWORD_LENGTH: "Password must have a minimum length of 8.",
  MAX_PASSWORD_LENGTH: "Password must have a maximum length of 25.",

  //invest
  MINIMUN_INVEST: "Captial commitment amount cannot be greater then ",
  MAX_INVEST: "Captial commitment amount cannot less then ",
  SESSION_EXPIRED: "Invalid session for the user, session is expired.",

  //Contact us form
  SUBJECT: "Please enter subject.",
  MESSAGE: "Please enter your message.",
  CONT_PREF: "Please select contact preference",
  MIN_MESSAGE_LENGTH: "Message must have a minimum length of 10.",
  MAX_MESSAGE_LENGTH: "Message must be less than 500 characters.",
};
export default errors;
