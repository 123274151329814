import { ThemeProvider } from "styled-components";
import { DarkTheme } from "./Themes";
import MainSectionWrapper from "./components/mainSection";
import "./App.css";
import { useCallback, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { useAppDetails } from "./hooks/useAppDetails";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { getTotalPortfolioValue } from "./services/charts/charts";
import { removeUserData, updatePortfolio } from "./store/actions/userActions";
import { removeAccessToken } from "./store/actions/loginActions";
import { useNavigate } from "react-router-dom";
Amplify.configure({
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID.replace(";", ""),
  aws_user_pools_web_client_id: process.env.REACT_APP_CLIENT_ID.replace(
    ";",
    ""
  ),
});

function App() {
  const dispatch = useDispatch();
  const { userData } = useAppDetails();
  const { themeDetail } = useAppDetails();

  // useEffect(() => {
  //   if (!themeDetail?.themeType) {
  //     dispatch(changeTheme({ themeType: "light" }));
  //   }
  // }, [dispatch, themeDetail]);

  const totalPortFolioValue = useCallback(
    async (params) => {
      try {
        let res = await getTotalPortfolioValue(params);
        dispatch(updatePortfolio(res?.data?.totalPortfolioValue));
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (userData?.attributes?.wallet_address) {
      totalPortFolioValue({
        wallet_address: userData?.attributes?.wallet_address,
      });
    }
  }, [totalPortFolioValue, userData?.attributes?.wallet_address]);

  return (
    <>
      <ThemeProvider theme={DarkTheme}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          theme={themeDetail.themeType}
        />
        <div
          style={
            themeDetail.themeType === "light"
              ? { backgroundColor: "white" }
              : { backgroundColor: "black" }
          }
        >
          <MainSectionWrapper />
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
