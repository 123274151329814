import {
  DownOutlined,
  MessageOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import { StyledNotificationWrapper } from "../style";
import { StyledPaginationWrapper } from "../../pagination/style";

export default function Notification({
  loading,
  notificationData,
  handleNext,
  pagination,
}) {
  return (
    <StyledNotificationWrapper>
      {loading && <Spin />}
      {notificationData?.length ? (
        notificationData?.map((item, index) => {
          return (
            <>
              <div key={index} className="notification-container">
                <MessageOutlined />

                <span>
                  {item.message}
                  <span className="link-icon">
                    <a href={item.url} rel="noreferrer" target="_blank">
                      <SelectOutlined />
                    </a>
                  </span>
                </span>
              </div>
            </>
          );
        })
      ) : (
        <div className="no-data">No Data</div>
      )}

      {pagination?.hasMore && (
        <StyledPaginationWrapper>
          <div className="next-arrow">
            <DownOutlined onClick={handleNext} />
          </div>
        </StyledPaginationWrapper>
      )}
    </StyledNotificationWrapper>
  );
}
