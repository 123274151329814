import ActionTypes from "../variables";

const setAccessToken = (data) => {
  return {
    type: ActionTypes.SET_ACCESS_TOKEN,
    payload: data,
  };
};

const removeAccessToken = (data) => {
  return {
    type: ActionTypes.REMOVE_ACCESS_TOKEN,
    payload: data,
  };
};

export { setAccessToken, removeAccessToken };
