const routes = {
  WELCOME: "/welcome",
  Dashboard: "/dashboard",

  //Assets
  ASSETS: "/my-assets",
  TEMPLATE_DETAIL: "template-detail",
  ASSETS_INSTANCE: "assets-instance",

  //Invest
  INVEST: "/invest",
  INVEST_INSTANCE: "instance",
  INDEX_TEMPLATE: "index",

  //Schedule
  SCHEDULE: "/schedule",
  INSTANCE_DETAIL: "instance-info",

  MY_ACCOUNT: "my-account",
  SETTINGS: "settings",
  CONTACTUS: "contact",
  CONTACT_US_REDIRECTS: "contact-us",
  MFA: "/mfa",
  CREATE: "/create",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGET_PASSWORD: "/forgot-password",
  FORGET_USERNAME:"/forgot-username",
  INVEST_INFO: "instance-details",
  CHANGE_PASSWORD: "/change-password",
  INVEST_REQUEST: "/invest-request",
  PURCHASING_POWER: "/purchasing-power",
  INSTANCE_DETAILS: "/instance-info",
};
export default routes;
