import { BarsOutlined, BellFilled } from "@ant-design/icons";
import { StyledDropdown, StyledHeaderWrapper, StyledPopover } from "./style";
import logo from "../../assets/icons/logo.svg";
import { Avatar } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import DrawerContent from "./component/drawerContent/DrawerContent";

import { useDispatch } from "react-redux";
import { useAppDetails } from "../../hooks/useAppDetails";
import Spinner from "../spinner/Spinner";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes";
import { removeUserData } from "../../store/actions/userActions";
import { getFirstLetter } from "../../common/utility";
import {
  logoutService,
  updateUserAttributes,
} from "../../services/aws-services/aws-services";
import Notification from "./component/Notification";
import { removeAccessToken } from "../../store/actions/loginActions";
import { userNotification } from "../../services/notification/notification";
import { toast } from "react-toastify";
import { setDrawerStatus } from "../../store/actions/drawerAction";

export default function Header({ open, setOpen }) {
  const { loginDetail, userData, drawerDetails } = useAppDetails();
  const [loading, setLoading] = useState(false);
  const [popuploading, setPopuploading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [showCount, setShowCount] = useState(true);
  const [pagination, setPagination] = useState({
    hasMore: "",
    next: "",
    limit: "",
    hasPrev: "",
    prev: "",
  });

  const dispatch = useDispatch();
  const bellRef = useRef();
  const navigate = useNavigate();

  const handleNotificationClick = (visible) => {
    if (visible) {
      setShowCount(false);
    } else {
      setShowCount(true);
    }
  };

  const handleLogoClick = () => {
    navigate(routes.Dashboard);
  };

  const items = [
    {
      label: (
        <div className="menu-label" onClick={(e) => navigate(routes.SETTINGS)}>
          My Account
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="menu-label" onClick={(e) => logout()}>
          Logout
        </div>
      ),
      key: "3",
    },
  ];

  const handleNotificationNext = async () => {
    await fetchAllNotification({ next: pagination?.next });
  };

  const showDrawer = () => {
    if (loginDetail.token) {
      setOpen(true);
      dispatch(setDrawerStatus(true));
    }
  };

  const onClose = () => {
    dispatch(setDrawerStatus(false));
    setOpen(false);
  };

  const fetchAllNotification = useCallback(async (params) => {
    try {
      setPopuploading(true);
      let res = await userNotification(params);
      let { data, ...tempPaginationData } = res?.data;
      setNotificationData((prev) => [...prev, ...data]);
      setPagination((prevState) => ({
        ...prevState,
        hasMore: res?.data?.hasMore,
        next: res?.data?.next,
        prev: res?.data?.previous,
      }));
      setPopuploading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setPopuploading(false);
    }
  }, []);

  useEffect(() => {
    if (loginDetail.token) {
      fetchAllNotification();
    }
  }, [loginDetail.token, fetchAllNotification]);

  const removeUserAllData = () => {
    dispatch(removeAccessToken(null));
    dispatch(removeUserData());
    window.localStorage.clear();
    navigate("/login");
  };

  const logout = async (e) => {
    setLoading(true);

    try {
      await updateUserAttributes(
        "custom:last_logout",
        `${new Date().getTime()}`
      );
      await logoutService();
      removeUserAllData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (
        error?.message === "User is disabled." ||
        error?.message === "Access Token has been revoked"
      ) {
        removeUserAllData();
      }
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <StyledHeaderWrapper open={open}>
        <div className="left-section">
          {loginDetail.token && <BarsOutlined onClick={showDrawer} />}
          {loginDetail.token && <p>Quick Index List</p>}
        </div>
        <div className="logo" onClick={handleLogoClick}>
          <img src={logo} alt="" />
          <p>
            On<span>-</span>Chain Index
          </p>
        </div>
        <div className="right-section">
          {/* {loginDetail.token && <p>News & Articles</p>} */}
          {loginDetail.token && (
            <>
              <StyledPopover
                className="test"
                title="Notification"
                placement="bottomLeft"
                onVisibleChange={handleNotificationClick}
                content={() => (
                  <Notification
                    notificationData={notificationData}
                    loading={popuploading}
                    pagination={pagination}
                    handleNext={handleNotificationNext}
                  />
                )}
                getPopupContainer={(trigger) =>
                  bellRef?.current || trigger.parentNode
                }
                trigger="click"
              >
                <BellFilled ref={bellRef} />
                {showCount && notificationData?.length > 0 && (
                  <div className="count">{}</div>
                )}
              </StyledPopover>
            </>
          )}
          {loginDetail.token && (
            <div className="drop-down-container">
              <StyledDropdown
                menu={{
                  items,
                }}
                trigger="click"
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Avatar
                  style={{ backgroundColor: "#87d068" }}
                  icon={
                    userData?.attributes?.profile ? (
                      <img
                        className="profile-img"
                        src={userData?.attributes.profile}
                        key={userData?.attributes.profile}
                        alt=""
                      />
                    ) : (
                      <div className="user-name-letter">
                        {getFirstLetter(
                          userData?.attributes?.preferred_username
                        )}
                      </div>
                    )
                  }
                ></Avatar>
              </StyledDropdown>
            </div>
          )}
        </div>
      </StyledHeaderWrapper>

      <DrawerContent
        open={drawerDetails.data}
        onclose={onClose}
        setOpen={setOpen}
        placement="left"
      />
    </>
  );
}
