import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { StyledMainSection } from "./MainSection";
import routes from "../../constants/routes";
import LandingPage from "../../pages/landingPage";
import ForgetUsername from "../../pages/forget-password/component/ForgetUsername";
const Login = React.lazy(() => import("../../pages/loginPage"));
const ForgetPassword = React.lazy(() => import("../../pages/forget-password"));
const ProtectedRoutes = React.lazy(() =>
  import("../../routes/ProtectedRoutes")
);

export default function MainSectionWrapper() {
  return (
    <>
      <StyledMainSection>
        <Routes>
          <Route
            path={routes.LOGIN}
            element={
              <Suspense fallback={<LandingPage />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={routes.FORGET_PASSWORD}
            element={
              <Suspense fallback={<LandingPage />}>
                <ForgetPassword />
              </Suspense>
            }
          />
                <Route
            path={routes.FORGET_USERNAME}
            element={
              <Suspense fallback={<LandingPage />}>
                <ForgetUsername />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<LandingPage />}>
                <ProtectedRoutes />
              </Suspense>
            }
          />
        </Routes>
      </StyledMainSection>
    </>
  );
}
