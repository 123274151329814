import { Dropdown, Popover } from "antd";
import styled from "styled-components";

export const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background: ${(props) => props.theme.colors.formBodyBackground};
  color: ${(props) => props.theme.colors.headerText};
  height: 45px;
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;

  .ant-dropdown-menu {
    background-color: ${(props) => props.theme.colors.cardColor};
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
    background-color: transparent;
  }

  .menu-label:hover {
    color: ${(props) => props.theme.colors.themeGreen} !important;
  }

  .ant-dropdown-arrow::before {
    background-color: ${(props) => props.theme.colors.cardColor};
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .next-arrow {
      .anticon svg {
        display: inline-block;
        height: 50px;
        width: 76px;
        color: #fd9379;
        padding-bottom: 18px;
      }
    }
  }

  .left-section {
    gap: 15px;
    p {
      margin-top: revert;
    }
    .anticon {
      font-size: 22px;
      color: red;
    }
  }
  .logo {
    cursor: pointer;
    justify-content: ${(props) =>
      props.open ? "flex-start" : "center"} !important;

    p {
      margin-top: revert;
    }
    /* justify-content: center; */
    img {
      width: 45px;
      height: 45px;
      margin-top: 10px;
    }
    p {
      color: red;
      font-weight: bold;
      span {
        color: #00df9a;
      }
    }
  }

  .right-section {
    .checkbox {
      opacity: 0;
      position: absolute;
    }
    .menu-label {
      background-color: ${(props) => props.theme.colors.cardColor};
      color: ${(props) => props.theme.colors.inputBackgroundColor};
    }

    .label {
      width: 40px;
      height: 20px;
      background-color: #111;
      cursor: pointer;
      display: flex;
      border-radius: 50px;
      align-items: center;
      justify-content: space-between;
      padding: 3px;
      position: relative;
      transform: scale(1.3);
    }

    .ball {
      width: 16px;
      height: 16px;
      background-color: white;
      position: absolute;
      border-radius: 50%;
      left: 2px;
      transition: transform 0.2s linear;
    }

    p {
      margin-top: revert;
    }
    gap: 15px;
    justify-content: end;
    .anticon-bell {
      font-size: 20px;
    }
    .ant-avatar-icon {
      height: 25px;
      background-color: rgb(135, 208, 104);
      border: 1px solid red;
      width: 25px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
    }
    p {
      font-size: 12px;
    }
    .user-name-letter {
      font-size: medium;
    }

    .ant-popover-inner-content {
      padding: 12px 0;
    }
  }

  .logo,
  .left-section,
  .right-section {
    display: flex;
    flex: 1;
    align-items: center;

    p {
      font-size: 12px;
    }
  }
  .left-section {
    display: ${(props) => (props.open ? "none" : "flex")} !important;
  }
`;

export const StyledDrawerContentWrapper = styled.div`
  p {
    margin-top: revert;
  }
  .drawer-header {
    display: flex;

    .bars,
    .onclose {
      display: flex;
      flex: 2;
      align-items: center;
      p {
        font-size: 14px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.inputBackgroundColor};
      }
    }
    .bars {
      gap: 15px;
      .anticon-bars {
        color: red;
        font-size: 25px;
      }
    }
    .onclose {
      flex: 1;
      justify-content: end;
      .anticon-close {
        color: red;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .drawer-table {
    padding-top: 20px;

    .tableTitle {
      font-size: ${(props) => props.theme.fontSize[2]}px;
      padding-bottom: 7px;
      font-weight: 600;
    }
    thead {
      tr {
        font-size: ${(props) => props.theme.fontSize[1] - 1}px;
        font-weight: 600;
        color: ${(props) => props.theme.colors.themeGreen};
        text-align: justify;
        .addMergin {
          padding-left: 43px;
        }
        th {
          padding-bottom: 13px;
        }
      }
    }

    tbody {
      .entity-font {
        color: ${(props) => props.theme.colors.red};
      }

      td {
        font-size: ${(props) => props.theme.fontSize[1]}px;
        padding-bottom: 15px;
        color: ${(props) => props.theme.colors.inputBackgroundColor};
      }
      .addLeftMergin {
        padding-left: 43px;
      }
    }
  }
`;

export const StyledNotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  .pagination {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
    button {
      font-size: ${(props) => props.theme.fontSize[4]}px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.inputBackgroundColor};
      width: 20px;
      height: 30px;
      font-weight: 600;
      border-color: #ff4d4f;
      background: transparent;
    }
    .prev {
      button {
      }
    }
  }

  .no-data {
    display: flex;
    height: 100px;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.inputBackgroundColor};
  }
  .notification-container {
    padding: 5px 20px;
    display: flex;
    gap: 20px;

    cursor: pointer;
    /* justify-content: space-between; */
    align-items: center;
    span {
      .link-icon {
        margin-left: 15px;
        .anticon svg {
          color: ${(props) => props.theme.colors.themeGreen};
        }
        .anticon svg:hover {
          color: ${(props) => props.theme.colors.red};
        }
      }
    }
  }
  .notification-container:hover {
    background-color: lightblue;
  }
  .message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
  }
  .anticon-bell {
    margin-top: 5px;
  }

  .ant-dropdown,
  .ant-dropdown-placement-bottomRight {
    width: 300px;
  }
  .notification-container {
    color: ${(props) => props.theme.colors.inputBackgroundColor} !important;
  }
  .notification-container:hover {
    background-color: ${(props) => props.theme.colors.cardColor} !important;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  background-color: red;

  .ant-dropdown-menu {
    background: ${(props) => props.theme.colors.red} !important;
  }
`;
export const StyledPopover = styled(Popover)`
  position: relative;
  margin-top: 5px;

  .count {
    position: absolute;
    top: -2px;
    color: red;
    right: 0px;
    font-weight: 600;
    border: 1px solid red;
    border-radius: 77px;
    width: 8px;
    height: 8px;
    font-size: 9px;
    display: flex;
    background: red;
    top: -2px;
    color: red;
    left: 12px;
    justify-content: center;
    cursor: pointer;
  }
  .ant-popover
    > .ant-popover-content
    > .ant-popover-inner
    > .ant-popover-inner-content::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  .ant-popover
    > .ant-popover-content
    > .ant-popover-inner
    > .ant-popover-inner-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .ant-popover
    > .ant-popover-content
    > .ant-popover-inner
    > .ant-popover-inner-content::-webkit-scrollbar-thumb {
    margin-top: 50px;
    background: grey;
    border-radius: 10px;
  }
  .ant-popover {
    width: 300px;
  }
  .ant-popover
    > .ant-popover-content
    > .ant-popover-inner
    > .ant-popover-inner-content {
    height: 200px;
    overflow-y: auto;
  }

  .ant-popover
    > .ant-popover-content
    > .ant-popover-inner
    > .ant-popover-title {
    font-weight: bold;
  }

  .ant-popover > .ant-popover-content > .ant-popover-arrow {
    display: none !important;
  }

  .ant-popover-inner {
    background-color: ${(props) => props.theme.colors.cardColor} !important;
  }
  .ant-popover-title {
    color: ${(props) => props.theme.colors.inputBackgroundColor} !important;
  }
`;
