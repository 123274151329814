import ActionTypes from "../variables";
const initialState = [];
export const investReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_INVEST_DETAILS:
      return {
        ...state,
        data: payload,
      };

    default:
      return state;
  }
};
